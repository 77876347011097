import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import Content, { HTMLContent } from '../components/Content';


const StyledArticle = styled.article`
	.main-section {
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		padding-top: 15rem;
		padding-bottom: 15rem;

		@media (max-width: 768px) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
	}

	.sub-head {
		font-size: 2rem;
		font-weight: ${(props) => props.theme.fonts.weights.bold};
		text-transform: uppercase;
		letter-spacing: 4.2px;
		margin-top: 2rem;
	}

	.big-n-gold {
		font-size: 3rem;
		font-weight: ${(props) => props.theme.fonts.weights.bold};
		color: ${(props) => props.theme.colors.tertiary};
		text-transform: uppercase;
		letter-spacing: 4.2px;
		margin-top: 2rem;
	}

	.details {
		margin-top: 3rem;
	}

	.body-section {
		padding-top: 5rem;
		padding-bottom: 10rem;

		.body-content {
			a {
				color: ${(props) => props.theme.colors.primary};
				text-decoration: underline;
				transition: 300ms;

				&:hover {
					color: ${(props) => props.theme.colors.textGrey};
				}
			}

			h2 {
				color: ${(props) => props.theme.colors.tertiary};
				font-size: 2.1rem;
				margin: 2rem 0;

				@media (max-width: 768px) {
					font-size: 1.8rem;
				}
			}

			p {
				font-size: 1.6rem;
			}

			ul {
				margin: 1rem 0;

				li {
					font-size: 1.6rem;
					font-style: italic;
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					padding-left: 2rem;
					margin-bottom: 1rem;
					position: relative;

					&::before {
						content: '';
						width: 0.7rem;
						height: 0.7rem;
						background-color: ${(props) => props.theme.colors.primary};
						border-radius: 50%;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}

			ol {
				margin: 1rem 0;

				li {
					font-style: italic;
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					margin-left: 2rem;
					margin-bottom: 1rem;
				}
			}

			blockquote {
				padding-left: 2rem;
				border-left: 5px solid ${(props) => props.theme.colors.textGrey};
			}
		}

		.button {
			margin-top: 5rem;

			@media (min-width: 769px) {
				float: right;
				margin-right: 3rem;
			}
		}
	}
`;

export const PolicyPageTemplate = ({ title, background, content, contentComponent }) => {
	const PostContent = contentComponent || Content;

	return (
		<StyledArticle>
			<section
				className="section main-section"
				style={{
					backgroundImage: `url(${background.childImageSharp.fluid.src})`,
				}}
			>
				<div className="container">
					<div className="columns is-centered">
						<div className="column is-8 has-text-centered">
							<h1>{title}</h1>
							<AccentLine align="center" />
						</div>
					</div>
				</div>
			</section>
			<section className="section body-section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<PostContent className="body-content" content={content} />
						</div>
					</div>
				</div>
			</section>
		</StyledArticle>
	);
}

PolicyPageTemplate.propTypes = {
	title: PropTypes.string,
};

const TermsPage = ({ data }) => {
	const { frontmatter, html } = data.markdownRemark;

	return (
		<Layout page="privacy-policy">
			<SEO
				title={frontmatter.title}
				description="We're here to help! Submit your enquiry via our website form for a resonse within 24hrs. If you are looking to connect directly, call 705-722-9111."
			/>
			<PolicyPageTemplate
				title={frontmatter.title}
				background={frontmatter.main_bg}
				content={html}
				contentComponent={HTMLContent}
			/>
		</Layout>
	);
};

TermsPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default TermsPage;

export const termsPageQuery = graphql`
	query TermsPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				main_bg {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			html
		}
	}
`;
